import { ButtonProps, RadioProps, TextFieldProps } from "@material-ui/core";
import theme from "./theme";

const selectButtonsProps: {
  muiButtonProps: ButtonProps;
  muiButtonActiveProps: ButtonProps;
  gridRowGap: number;
  gridColumnGap: number;
  muiRadioActiveProps: RadioProps;
  muiRadioProps: RadioProps;
} = {
  muiButtonProps: {
    variant: "text",
    color: "primary",
    style: {
      backgroundColor: theme.palette.grey[30],
      borderLeft: `2px solid ${theme.palette.primary.light}`,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[2],
    },
    disableElevation: false,
  },
  muiButtonActiveProps: {
    variant: "contained",
    color: "primary",
    style: { backgroundColor: theme.palette.primary.light, boxShadow: theme.shadows[4] },
    disableElevation: false,
  },
  muiRadioProps: { size: "small" },
  muiRadioActiveProps: { style: { color: theme.palette.grey[200] }, size: "small" },
  gridRowGap: 6,
  gridColumnGap: 6,
};

const muiTextFieldProps: TextFieldProps = { type: "number", fullWidth: true, variant: "outlined" };

const settings = {
  navBarHeight: "65px",
  navigationButtonsBarHeight: "90px",
  selectButtonsProps: selectButtonsProps,
  muiTextFieldProps: muiTextFieldProps,
};

export default settings;
